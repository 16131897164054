<template>
  <div class="debris flex-row">
    <div v-if="debrisLanguageType == 1" class="container-left flex-col">
      <span>华丽岛</span>
      <span
        >本片讲述一个幼小的女孩在父亲离家后，独自在残骸环境中长大，并找回父亲的故事。</span
      >
    </div>
    <div
      v-if="debrisLanguageType == 2"
      class="container-left en-language flex-col"
    >
      <span>DEBRIS</span>
      <span
        >DEBRIS tells the story of a young girl who, as she grows up alone in an
        environment of wreckage after her father leaves home, wanders in search
        of him.</span
      >
    </div>
    <div class="container-right">
      <swiper :options="swiperOption">
        <swiper-slide v-for="(item, index) in imgList" :key="index">
          <img class="slide-img" :src="item.url" alt="" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
    <img
      class="close"
      @click="goBack"
      src="../assets/icon/close_b.png"
      alt=""
    />
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // 设置点击可切换
          clickable: true,
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 设置自动轮播
        autoplay: {
          delay: 3000, // 3秒切换一次
        },
        // 设置轮播可循环
        loop: true,
      },
      imgList: [
        {
          url: require("@/assets/image/debris/1.jpg"),
        },
        {
          url: require("@/assets/image/debris/2.png"),
        },
        {
          url: require("@/assets/image/debris/3.jpg"),
        },
        {
          url: require("@/assets/image/debris/4.jpg"),
        },
        {
          url: require("@/assets/image/debris/5.jpg"),
        },
        {
          url: require("@/assets/image/debris/6.jpg"),
        },
        {
          url: require("@/assets/image/debris/7.jpg"),
        },
        {
          url: require("@/assets/image/debris/8.jpg"),
        },
        {
          url: require("@/assets/image/debris/9.jpg"),
        },
        {
          url: require("@/assets/image/debris/10.jpg"),
        },
        {
          url: require("@/assets/image/debris/11.jpg"),
        },
        {
          url: require("@/assets/image/debris/12.jpg"),
        },
        {
          url: require("@/assets/image/debris/13.jpg"),
        },
        {
          url: require("@/assets/image/debris/14.jpg"),
        },
        {
          url: require("@/assets/image/debris/15.jpg"),
        },
      ],
    };
  },
  created() {},
  computed: {
    debrisLanguageType() {
      return this.$store.state.debrisLanguageType;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
.debris {
  width: 100%;
  height: 8.16rem;
  position: relative;
  background-color: #c5c5c5;

  .container-left {
    width: 3.84rem;
    height: 100%;
    padding: 0.26rem;

    span {
      display: inline-block;
      width: 3.35rem;
      line-height: 0.24rem;
      &:nth-child(1) {
        margin-bottom: 1.45rem;
        font-size: 0.4rem;
        line-height: 0.48rem;
        font-family: "Alibaba-PuHuiTi-Bold";
      }
    }
  }

  .en-language {
    span {
      font-family: "Poppins";
      &:nth-child(1) {
        font-family: "Poppins Bold";
      }
    }
  }

  .container-right {
    width: calc(100% - 3.84rem);
    height: 100%;
    position: relative;
    .swiper-slide {
      width: 15.36rem;
      height: 8.16rem;
      background-color: #c5c5c5;

      .slide-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .close {
    width: 0.25rem;
    height: 0.25rem;
    position: absolute;
    left: 0.23rem;
    bottom: 0.23rem;
    cursor: pointer;
  }
}
</style>
