<template>
  <div class="footer">
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {

    }
  },
  created() { },
  methods: {},

}
</script>

<style lang="scss">
.footer {
  z-index: 999;
  width: 100%;
  height: calc((100vh - 8.16rem)/2);
  min-height: 0.5rem;
  background-color: #262626;
}</style>
