<template>
  <div id="app" v-if="isReady">
    <router-view />
  </div>
</template>

<script>
const packageInfo = require("../package.json");

export default {
  name: 'App',
  components: {},
  data() {
    return {
      isReady: false,
    };
  },
  async created() {
    const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
		// 如果是手机端访问
    if (flag) {
			window.location.replace('https://debrisanimation.com/h5')
      return
		}
    this.copyRightConsole(packageInfo);
    // 监听网络
    // 网络由异常到正常时触发
    window.addEventListener("online", this.updateOnlineStatus);
    // 网络由正常到异常触发
    window.addEventListener("offline", this.updateOfflineStatus);

    // 利用第三方接口，判断外网ip是否在国内，国内默认用中文，国外默认用英文
    try {
      const resp = await fetch('https://jsonip.com', { mode: 'cors' });
      const res = await resp.json();
      console.log(res.country);
      if (res.country == "CN") {
        this.$store.dispatch("languageTypeActions", 1);
      } else {
        this.$store.dispatch("languageTypeActions", 2);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    } finally {
      this.isReady = true;
    }
  },
  methods: {
    updateOnlineStatus() {
      // this.$message({
      //   type: "success",
      //   center: true,
      //   message: "网络连接成功",
      // });
      window.location.reload();
    },
    updateOfflineStatus() {
      // this.$message({
      //   type: "error",
      //   center: true,
      //   message: "网络断开连接",
      // });
    },
    // 在console中显示版本等信息
    copyRightConsole(packageData) {
      /* 样式代码 */
      const projectNameStyle =
        "font-size: 20px;font-weight: 600;color: rgb(244,167,89);";
      const descriptionStyle =
        "font-style: oblique;font-size:14px;color: rgb(244,167,89);font-weight: 400;";
      const versionStyle = "color: rgb(30,152,255);padding:8px 0 2px;";

      /* 内容代码 */
      const projectName = packageData.name || "";
      const description = packageData.description || "";
      const version = `版 本 号：${packageData.version}`;
      const dateTime = `编译日期：${new Date()}`;

      // 空格有意义，不要格式化
      console.log(
        `%c${description} %c${projectName}
%c${version}
${dateTime}`,
        projectNameStyle,
        descriptionStyle,
        versionStyle
      );
    },
  },
}
</script>

<style lang="scss">
@import "./styles/base.css";
@import "./styles/flex.css";

#app {
  font-size: 0.20rem;
  font-family: "Alibaba-PuHuiTi-Regular";
}
</style>
