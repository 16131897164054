<template>
  <div class="works flex-row">
    <div class="item1" @click="goPigSale">
      <div v-if="debrisLanguageType == 1" class="mask-view">
        <span class="title">卖猪</span>
      </div>
      <div v-if="debrisLanguageType == 2" class="mask-view">
        <span class="title poppinsBold">PIG SALE</span>
      </div>
    </div>
    <div class="item2" @click="goXiurongTown">
      <div v-if="debrisLanguageType == 1" class="mask-view">
        <span class="title">修容镇</span>
      </div>
      <div v-if="debrisLanguageType == 2" class="mask-view">
        <span class="title poppinsBold">THE TOWN</span>
      </div>
    </div>
    <div class="item3" @click="goDebris">
      <div v-if="debrisLanguageType == 1" class="mask-view">
        <span class="title">华丽岛</span>
      </div>
      <div v-if="debrisLanguageType == 2" class="mask-view">
        <span class="title poppinsBold">DEBRIS</span>
      </div>
    </div>
    <img
      class="close"
      @click="goBack"
      src="../assets/icon/close_w.png"
      alt=""
    />
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  computed: {
    debrisLanguageType() {
      return this.$store.state.debrisLanguageType;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goPigSale() {
      this.$router.push({ name: "PigSale" });
    },
    goXiurongTown() {
      this.$router.push({ name: "XiurongTown" });
    },
    goDebris() {
      this.$router.push({ name: "Debris" });
    },
  },
};
</script>

<style lang="scss">
.works {
  width: 100%;
  height: 8.16rem;
  position: relative;
  overflow: hidden;

  .item1,
  .item2,
  .item3 {
    width: 33.33%;
    height: 100%;
    cursor: pointer;
    &:hover {
      .mask-view {
        display: block;
      }
    }
    .mask-view {
      width: 100%;
      height: 100%;
      display: none;
      padding: 0.24rem 0 0 0.24rem;
      .title {
        font-size: 0.4rem;
        line-height: 0.48rem;
        color: #fff;
        font-family: 'Alibaba-PuHuiTi-Bold';
      }
      .poppinsBold {
        font-family: 'Poppins Bold';
      }
    }
  }
  .item1 {
    background: url("../assets/image/others/4.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .mask-view {
      background: url("../assets/image/others/4s.jpg");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  .item2 {
    background: url("../assets/image/others/5.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .mask-view {
      background: url("../assets/image/others/5s.jpg");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  .item3 {
    width: calc(100% - 66.66%);
    background: url("../assets/image/others/6.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .mask-view {
      background: url("../assets/image/others/6s.jpg");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  .close {
    width: 0.25rem;
    height: 0.25rem;
    position: absolute;
    left: 0.23rem;
    bottom: 0.23rem;
    cursor: pointer;
  }
}
</style>
