<template>
  <div class="banner flex-row justify-between align-center" @click.stop="goHome">
    <img v-if="debrisLanguageType == 1" class="logo" :style="currentPathName == 'Home' ? 'display: block' : ''" src="../../assets/icon/logo_cn.png" alt="">
    <img v-if="debrisLanguageType == 2" class="logo" :style="currentPathName == 'Home' ? 'display: block' : ''" src="../../assets/icon/logo_en.png" alt="">
    <div class="language flex-row align-center" :style="currentPathName == 'Home' ? 'display: block' : ''"
      @click.stop="switchLanguage">
      <img v-if="debrisLanguageType == 2" src="../../assets/icon/en.png" alt="">
      <img v-if="debrisLanguageType == 1" src="../../assets/icon/cn.png" alt="">
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
    }
  },
  created() { },
  computed: {
    debrisLanguageType() {
      return this.$store.state.debrisLanguageType;
    },
    currentPathName() {
      return this.$route.name;
    },
  },
  methods: {
    goHome() {
      if (this.currentPathName == 'Home') return
      this.$router.push('/');
    },
    switchLanguage() {
      if (this.debrisLanguageType == 1) {
        this.$store.dispatch("languageTypeActions", 2);
      } else {
        this.$store.dispatch("languageTypeActions", 1);
      }
    }
  }
}
</script>

<style lang="scss">
.banner {
  width: 100%;
  height: calc((100vh - 8.16rem)/2);
  min-height: 0.5rem;
  background-color: #262626;
  padding: 0 0.24rem;
  transition: all 0.2s;
  cursor: pointer;
  z-index: 999;

  &:hover {
    .logo,
    .language {
      display: block;
    }
  }

  .logo {
    height: 80%;
    max-height: 1.08rem;
    display: none;
  }

  .language {
    height: 30%;
    max-height: 0.54rem;
    display: none;

    img {
      height: 100%;
      position: relative;
      top: 0.02rem;
    }
  }
}
</style>
